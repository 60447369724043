import logo from "./logo.svg";
import "./App.css";
import "./font.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Intro from "./pages/Intro";
import CheckPhoneShopee from "./pages/CheckPhoneShopee";
import UploadImageShopee from "./pages/UploadImageShopee";
import SuccessShopee from "./pages/SuccessShopee";
import IntroFlexivibe from "./pages/flexivibe/IntroFlexivibe";
import CheckPhoneFlexivibe from "./pages/flexivibe/CheckPhoneFlexivibe";
import UploadImageFlexivibe from "./pages/flexivibe/UploadImageFlexivibe";
import ListCustomerReceiveCode from "./pages/ListCustomerReceiveCode";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/:appcode/receive_code"
            element={<ListCustomerReceiveCode />}
          />

          {/* <Route path="/shopee" element={<Intro />} />
          <Route path="/shopee/checkphone" element={<CheckPhoneShopee />} />
          <Route path="/shopee/upload" element={<UploadImageShopee />} /> */}
          <Route path="/flexivibe" element={<IntroFlexivibe />} />
          <Route
            path="/flexivibe/checkphone"
            element={<CheckPhoneFlexivibe />}
          />
          <Route path="/flexivibe/upload" element={<UploadImageFlexivibe />} />

          {/* <Route path="/success/shopee" element={<SuccessShopee />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
