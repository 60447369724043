import INTRO_TOP from "../assets/image/intro-flex.png";
import INTRO_BOTTOM from "../assets/image/intro-shopee-bottom.png";
import { Fragment, useEffect, useState } from "react";
import { receiptServices } from "../services/apiServices";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Pagination } from "antd";

export default function ListCustomerReceiveCode() {
  const appCode = window.location.pathname.split("/")[1];
  const [lstCustomer, setLstCustomer] = useState([]);
  const [pageSize, setPageSize] = useState(1);
  const [pageNumber, setNumber] = useState(0);
  useEffect(() => {
    getWinCampaign();
  }, []);
  console.log(lstCustomer);
  const getWinCampaign = () => {
    const data = {
      campaign_name: appCode,
      page_size: 1,
      page_number: pageNumber,
    };
    receiptServices
      .getListCodeReceiveGift(data)
      .then((res) => {
        console.log(res?.data?.result?.lst_customer_receive);
        setLstCustomer(res?.data?.result?.lst_customer_receive);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleD = (data) => {
    setNumber(data);
    console.log(data);
    getWinCampaign();
  };
  return (
    <div className="bg-receive-code relative">
      <Header />
      <div className="px-2 mt-3">
        <table className="table-auto w-full text-[12px] leading-4 rounded-t-md">
          <thead className="h-6 rounded-t-md">
            <tr className="bg-[#003DA5] text-white font-light-mon rounded-t-2xl">
              <th className="border-head rounded-tl-xl pl-5 text-left">SĐT</th>
              <th className="border-hea">Tên</th>
              <th className="border-head rounded-tr-xl">Thời gian</th>
            </tr>
          </thead>
          {lstCustomer.length !== 0 ? (
            <tbody className="font-bold-mon">
              {lstCustomer.map((value, index) => {
                return (
                  <tr
                    className={index % 2 === 0 ? "bg-white" : "bg-[#F0F0F0]"}
                    key={index}
                  >
                    <td className="">{value.phone_display}</td>
                    <td className="">{value.name}</td>
                    <td className="">{value.date_submit}</td>
                  </tr>
                );
              })}{" "}
            </tbody>
          ) : null}
        </table>
        <div className="flex justify-center mt-2">
          <Pagination defaultCurrent={1} total={pageSize} onChange={handleD} />
          <div className="h-40"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
