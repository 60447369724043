import HEADER from "../assets/image/header-shopee.png";
import HEADER_FLEX from "../assets/image/header-flex.png";

export default function Header() {
  const appCode = window.location.pathname.split("/")[1];
  console.log(appCode);
  return (
    <div className="relative top-0 z-10">
      <img src={appCode === "flexivibe" ? HEADER_FLEX : HEADER} />
    </div>
  );
}
