import BG_LIGHT_CODE from "../assets/image/bg-ligh-code.png";
import BT_LEFT from "../assets/image/btn-hotline.png";
import GIFT from "../assets/image/gift-test.png";
import POPUP_GUIDE from "../assets/image/popup-guide-freefire.png";
import BT_RIGHT from "../assets/image/bt-right.png";
import GIFT_CODE from "../assets/image/code.png";
import POPUP_CARD_TOP from "../assets/image/popup-card-top.png";
import POPUP_CARD_BOTTOM from "../assets/image/popup-card-bottom.png";
import { Fragment, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SuccessShopee({ data, code, statusContent }) {
  const appCode = window.location.pathname.split("/")[1];
  const cpData = JSON.parse(localStorage.getItem("CAMPAIGN"));
  console.log(cpData);
  const [isOpenNoty, setIsOpenNoty] = useState(false);
  const navigation = useNavigate();
  const handleBack = () => {
    navigation(`/${appCode}`);
  };
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }
  const handleHotline = () => {
    if (appCode === "flexivibe") {
      setIsOpenNoty(!isOpenNoty);
    } else {
      window.open(`tel:${cpData.result?.hotline}`);
    }
  };
  return (
    <Fragment>
      {isOpenNoty ? (
        <div className="z-50">
          <div
            className="fixed z-50 overflow-y-auto top-0 w-full left-0 show"
            id="modal"
          >
            <div
              className="flex items-center justify-center min-height-100vh
          px-4 pb-20 text-center sm:block sm:p-0"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-900 opacity-70" />
                <img
                  src={POPUP_GUIDE}
                  className="relative z-50 top-10"
                  onClick={handleHotline}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative top-0 bg-black min-h-full">
          <img
            src={BG_LIGHT_CODE}
            className="animation-bg-code relative top-0"
          />
          <div className="absolute top-[10%] px-5 text-white">
            <div className="relative">
              <div className="relative">
                <div className="relative top-0">
                  <img src={POPUP_CARD_TOP} />
                  {!statusContent ? (
                    <div className="absolute bottom-10 text-justify text-[12px] px-10 w-full">
                      {appCode === "flexivibe" ? (
                        <Fragment>
                          Trong vòng 1 tiếng, hệ thống sẽ kiểm tra và gửi kết
                          quả thông qua SMS/ ZNS của PG VN{" "}
                        </Fragment>
                      ) : (
                        <Fragment>
                          Sau 7 ngày sẽ nhận được Mã thông qua SMS/ ZNS của PG
                          VN
                        </Fragment>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="absolute top-10 w-full">
                  <div className="relative text-center">
                    <div className="font-bold-mon text-[28px] text-[#FFED00] mt-10">
                      THÔNG BÁO
                    </div>
                  </div>
                </div>
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-11/12 px-5">
                  <div className="relative top-0 w-full">
                    {!statusContent && appCode === "flexivibe" ? (
                      <Fragment>
                        <div className="flex justify-center">
                          <img src={GIFT} />
                        </div>
                        <div className="relative z-40 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
                          {/* <img src={giftCodeDetails?.image} /> */}
                          <div className="relative top-5 w-full">
                            <div className="">
                              <div
                                className="relative w-full"
                                onClick={copyToClipboard}
                              >
                                <img
                                  src={GIFT_CODE}
                                  className="absolute z-30 w-full"
                                />
                                <input
                                  ref={textAreaRef}
                                  className="h-10 bg-transparent mt-3 w-full text-[24px] z-50 pr-5 font-semibold-mon text-center input-hidden-link"
                                  value={code}
                                />

                                {/* <div className="absolute z-50 top-1 left-1/2 -translate-x-1/2 pr-5 font-semibold-mon text-right input-hidden-link">
                      {giftCode}
                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <div className=" absolute font-bold-mon mt-10 text-[20px] w-full">
                        {data}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="relative top-0 w-full">
                <img src={POPUP_CARD_BOTTOM} className="relative top-0" />
                <div className="absolute top-1/2 -translate-y-1/2 left-[5px]">
                  <button
                    className="relative font-bold-mon w-10/12 left-1/2 -translate-x-1/2"
                    onClick={handleHotline}
                  >
                    <img src={BT_LEFT} />
                    <div
                      className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[14px] leading-4 w-full"
                      // onClick={handleMyGift}
                    >
                      {appCode === "flexivibe" ? (
                        <span>
                          HƯỚNG DẪN <br />
                          ĐỔI QUÀ
                        </span>
                      ) : (
                        <span>
                          LIÊN HỆ <br />
                          HOTLINE
                        </span>
                      )}
                    </div>
                  </button>
                </div>
                <div className="absolute top-1/2 -translate-y-1/2 right-[35px]">
                  <button
                    className="relative w-10/12 left-1/2 -translate-x-1/2"
                    onClick={handleBack}
                  >
                    <img src={BT_RIGHT} />
                    <div className="absolute font-bold-mon top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[16px] w-full">
                      <Fragment>TIẾP TỤC</Fragment>
                      {/* )} */}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
