import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: "https://glbetest.mvc.com.vn/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const axiosOCRInstance = axios.create({
  baseURL: "https://doiquapg.com",
  headers: {
    "Content-Type": "application/json",
  },
});
axiosInstance.interceptors.response.use(
  function (response) {
    console.log(response);
    if (response.data.result && response.data.result.meta) {
      if (!response.data.result.meta.status) {
        return Promise.reject(response.data.result);
      }
    }
    return response.data.result ? response.data.result : response;
  },
  function (error) {
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
