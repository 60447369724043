import { axiosInstance, axiosOCRInstance } from "./configURL";

export const receiptServices = {
  submitGilletteFlexivibe: (data) => {
    return axiosInstance.post("/api/flexivibe", {
      params: data,
    });
  },
  submitGilletteShopee: (data) => {
    return axiosInstance.post("/api/shopee", {
      params: data,
    });
  },
  uploadImageToOCR: (data) => {
    return axiosOCRInstance.post("/apinode/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  getDetailCampaign: (data) => {
    return axiosInstance.post("/api/get_campaign", {
      params: data,
    });
  },
  getListCodeReceiveGift: (data) => {
    return axiosInstance.post("/api/list_cus_receive_code", {
      params: data,
    });
  },
};
